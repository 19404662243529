exports.components = {
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sdanonymization-js": () => import("./../../../src/pages/sdanonymization.js" /* webpackChunkName: "component---src-pages-sdanonymization-js" */),
  "component---src-pages-sddocumentintelligence-js": () => import("./../../../src/pages/sddocumentintelligence.js" /* webpackChunkName: "component---src-pages-sddocumentintelligence-js" */),
  "component---src-pages-sdtemplate-js": () => import("./../../../src/pages/sdtemplate.js" /* webpackChunkName: "component---src-pages-sdtemplate-js" */),
  "component---src-pages-sdtranscription-js": () => import("./../../../src/pages/sdtranscription.js" /* webpackChunkName: "component---src-pages-sdtranscription-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

